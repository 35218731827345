import Cookies from 'js-cookie';
import { getSession, signOut } from 'next-auth/react';

import env from '@common/env';
import Logger from '@common/log';

import { LS_KEY_ACCOUNTS, LS_KEY_IMPERSONATION, LS_KEY_SELECTED_ACCOUNT } from './constants';

const dashboardUri = '/my-zone/dashboard';

type UserType = 'champion' | undefined;

export default async function logout(userType : UserType = undefined) {
  Cookies.remove(LS_KEY_ACCOUNTS);
  Cookies.remove(LS_KEY_IMPERSONATION);
  Cookies.remove(LS_KEY_SELECTED_ACCOUNT);
  // remove impersonation context but do not fully log out the user
  if (userType === 'champion') {
    window.location.href = dashboardUri;
    return;
  }

  // actually log out the user when not impersonating
  const session = await getSession();
  const signOutUrl= env('OKTA_OAUTH2_LOGOUT');

  if (session) {
    const oktaSignoutUrl = `${signOutUrl}?id_token_hint=${
      session?.idToken
    }&post_logout_redirect_uri=${env('OKTA_OAUTH2_LOGOUT_REDIRECT_URI')}`;
    signOut({ redirect: false }).then(() => (window.location.href = oktaSignoutUrl));
    return;
  }

  Logger.error('4xignI', 'Tried logging out without session');
}
