/* eslint-disable dxp-rules/no-inline-css */
import React, { FC } from 'react';

import { useFormatter } from '@i18n';
import { Box, Text, TextLink } from '@sparky';
import { CrmAccountWithRole } from 'types-be/next-auth';

import useSelfServiceAccount from '../hooks/useSelfServiceAccount';
import logout from '../utils/auth/logout';

interface ImpersonationFields {
  loggedInAsText: string;
  logoutText: string;
}

const fields: ImpersonationFields = {
  loggedInAsText: 'Logged in as: {alias} - {value}',
  logoutText: 'Log out',
};

const TopBarText = ({
  format,
  selectedAccount,
}: {
  format: (message: string, context: MessageContext) => string;
  selectedAccount: CrmAccountWithRole;
}) => (
  <>
    {format(fields.loggedInAsText, {
      alias: (selectedAccount?.alias as string) ?? '',
      value: selectedAccount?.crmAccountNumber,
    })}
  </>
);

const ImpersonationBanner: FC<React.PropsWithChildren> = ({ children }) => {
  const { selectedAccount, isImpersonating } = useSelfServiceAccount();
  const { format } = useFormatter();

  if (!isImpersonating()) {
    return children;
  }
  return (
    <div>
      <div
        style={{
          borderColor: '#D21242',
          borderStyle: 'solid',
          borderWidth: '8px',
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: 3,
          pointerEvents: 'none',
        }}>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            backgroundColor: '#D21242',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            pointerEvents: 'auto',
          }}>
          <Box paddingX={6} paddingY={2}>
            <div style={{ display: 'flex', alignItems: 'baseline', flexDirection: 'row', gap: '24px' }}>
              <Text color={'textInverted'} weight={'bold'} size={'BodyXS'}>
                <TopBarText format={format} selectedAccount={selectedAccount} />
              </Text>
              <div style={{ fontSize: '12px', lineHeight: '16px' }}>
                <TextLink color={'textInverted'} emphasis="low" onClick={() => logout('champion')}>
                  {fields.logoutText}
                </TextLink>
              </div>
            </div>
          </Box>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ImpersonationBanner;
