import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

type AccountLinkDialogContextProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AccountLinkDialogContext = createContext<AccountLinkDialogContextProps>({ isOpen: false, setIsOpen: () => {} });

const AccountLinkDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <AccountLinkDialogContext.Provider value={{ isOpen, setIsOpen }}>{children}</AccountLinkDialogContext.Provider>
  );
};

export const useAccountLinkDialog = () => {
  const context = useContext(AccountLinkDialogContext);
  if (!context) throw new Error('useAccountLinkDialog must be wrapped inside AccountLinkDialogProvider');
  return [context.isOpen, context.setIsOpen] as const;
};

export default AccountLinkDialogProvider;
